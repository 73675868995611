<mat-toolbar class="justify-between">
  <!-- <button mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button> -->
  <span class="spacer">NakşEt sipariş</span>
  <div>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>insert_drive_file</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <!-- <button mat-menu-item (click)="export_to_csv()">
        <mat-icon>save_alt</mat-icon>
        <span>speichern als .csv NOT</span>
      </button> -->
      <button mat-menu-item (click)="exporter.exportTable('csv', {fileName:'bestellungen'})">
        <mat-icon>save_alt</mat-icon>
        <span>speichern als CSV</span>
      </button>
      <button mat-menu-item (click)="exporter.exportTable('xlsx', {fileName:'bestellungen'})">
        <mat-icon>save_alt</mat-icon>
        <span>speichern als EXCEL</span>
      </button>
      <button mat-menu-item (click)="SavePDF()">
        <mat-icon>save_alt</mat-icon>
        <span>speichern als PDF</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<mat-form-field class="example-full-width">
  <mat-label>Suchen</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>


<div id="content" #content>
  <table mat-table matTableExporter [dataSource]="all_orders" matSort class="mat-elevation-z8 table-responsive"
    #exporter="matTableExporter">

    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="firstname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Vorname </th>
      <td mat-cell *matCellDef="let row"> {{row.firstname}} </td>
    </ng-container>

    <ng-container matColumnDef="lastname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nachname </th>
      <td mat-cell *matCellDef="let element"> {{element.lastname}} </td>
    </ng-container>

    <ng-container matColumnDef="phonenumber">
      <th mat-header-cell *matHeaderCellDef> Telefonnummer </th>
      <td mat-cell *matCellDef="let element"> {{element.phonenumber}} </td>
    </ng-container>

    <ng-container matColumnDef="products">
      <th mat-header-cell *matHeaderCellDef> Produkte </th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let product of element.products" class="justify-row">
          <div *ngIf="product.product_id">{{product.quantity}} kg | {{product.product_id.name}} |
            {{(product.quantity*product.product_id.price).toFixed(2)}}€
          </div>
          <!-- for deleted products -->
          <div *ngIf="!product.product_id"><i style="color:#D3D3D3">Gelöschtes Produkt</i>
          </div>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="total_price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Gesamtpreis </th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{element.total_price}} €</td>
    </ng-container>

    <!-- <ng-container matColumnDef="timestamp">
    <th mat-header-cell *matHeaderCellDef> Bestellt am </th>
    <td mat-cell *matCellDef="let element">
      {{this.show_date(element.timestamp)}}</td>
  </ng-container> -->

    <ng-container matColumnDef="order_for_date_string">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Bestellt für den</th>
      <td mat-cell *matCellDef="let element"> {{element.order_for_date_string}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Kein Eintrag mit "{{input.value}}" gefunden.</td>
    </tr>
  </table>
</div>
<!-- 
<mat-form-field>
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate placeholder="Von" [(ngModel)]="start_date">
    <input matEndDate placeholder="Bis" [(ngModel)]="end_date">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 25px;"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
<button mat-menu-item (click)="get_by_date()">Test</button> -->