<mat-toolbar>
  <button (click)="drawer.toggle()" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <span class="spacer">NakşEt sipariş</span>
</mat-toolbar>

<mat-drawer-container autosize>
  <mat-drawer #drawer opened="false" mode="push" position="start">
    <mat-nav-list>
      <mat-list-item>
        <button routerLink="card" mat-button><mat-icon>shopping_basket</mat-icon> Alle Produkte</button>
      </mat-list-item>
      <div style="text-align: center;" href="www.ficus-coding.de">© 2023 FICUS Coding</div>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <div style="text-align: center;min-height: 600px;background-color: white;" class="justify-center">

      <mat-card class="mobile-width">

        <table cellspacing="0" id="desktop">
          <tr>
            <td><mat-form-field class="example-full-width">
                <mat-label>Vorname</mat-label>
                <input matInput [(ngModel)]="firstname">
              </mat-form-field></td>
            <td><mat-form-field class="example-full-width">
                <mat-label>Nachname</mat-label>
                <input matInput [(ngModel)]="lastname">
              </mat-form-field></td>
          </tr>
        </table>

        <div id="mobile">
          <mat-form-field class="example-full-width">
            <mat-label>Vorname</mat-label>
            <input matInput [(ngModel)]="firstname">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Nachname</mat-label>
            <input matInput [(ngModel)]="lastname">
          </mat-form-field>
        </div>

        <mat-form-field class="example-full-width">
          <mat-label>Telefonnummer</mat-label>
          <input matInput [(ngModel)]="phonenumber" type="tel">
        </mat-form-field>

        <table cellspacing="0" id="desktop">
          <tr>
            <td><mat-form-field>
                <mat-label>Produktauswahl</mat-label>
                <mat-select [(ngModel)]="product" name="meet">
                  <mat-option *ngFor="let product of all_products" [value]="product">
                    {{product.name}}, {{product.price}} €/kg
                  </mat-option>
                </mat-select>
              </mat-form-field></td>
            <td><mat-form-field class="example-full-width">
                <mat-label>kg</mat-label>
                <input matInput [(ngModel)]="quantity" type="number">
              </mat-form-field></td>
            <td><button mat-mini-fab class="green" (click)="add_chosen_products()" [disabled]="disable_add_product()">
                <mat-icon>add_shopping_cart</mat-icon>
              </button></td>
          </tr>
        </table>

        <div id="mobile">

          <mat-form-field class="example-full-width">
            <mat-label>Produktauswahl</mat-label>
            <mat-select [(ngModel)]="product" name="meet">
              <mat-option *ngFor="let product of all_products" [value]="product">
                {{product.name}}, {{product.price}} €/kg
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>kg</mat-label>
            <input matInput [(ngModel)]="quantity" type="number">
          </mat-form-field>
          <div style="text-align: right;">
            <button mat-mini-fab class="green" (click)="add_chosen_products()" [disabled]="disable_add_product()">
              <mat-icon>add_shopping_cart</mat-icon>
            </button>
          </div>
        </div>

        <table mat-table [dataSource]="chosen_products" class="example-full-width" *ngIf="this.chosen_products.length">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Produkt </th>
            <td mat-cell *matCellDef="let product"> {{product.name}} </td>
            <td mat-footer-cell *matFooterCellDef> <b>Gesamtpreis</b> </td>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Menge </th>
            <td mat-cell *matCellDef="let product"> {{product.quantity}} kg </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Preis </th>
            <td mat-cell *matCellDef="let product"> {{(product.price*product.quantity).toFixed(2)}} € </td>
            <td mat-footer-cell *matFooterCellDef> <b>{{getTotalCost()}} €</b></td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let product; let i = index" align="end"> <button mat-icon-button
                (click)="remove_chosen_product(i)">
                <mat-icon>delete</mat-icon>
              </button></td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let order; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

        <div>
          <mat-form-field class="example-full-width">
            <input #minmaxInput matInput [matDatepicker]="picker" [min]="minDate" [matDatepickerFilter]="dateFilter"
              placeholder="Datum zum Abholen der Bestellung" [(ngModel)]="order_date_for">
            <mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 25px;"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div align="end">
          <button mat-flat-button class="order" (click)="send_order()" [disabled]="disable_order()">Bestellen</button>
        </div>
      </mat-card>

      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>